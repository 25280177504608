//TODO: fix
//TODO: Refactoring

@import './variable.scss';
@import './normalize.scss';
@import './fonts/fonts.scss';
@import './components/all.scss';

ul {
  padding: 0 !important;
  margin: 0 !important;
  list-style: none;
}

.left-column {
  flex-shrink: 0;
  padding: 25px 0;
  width: 260px;
  z-index: 3;
  position: relative;
  overflow-y: auto;
  background: #fff;
}

.admin-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.5);
  height: 76px;
  flex-shrink: 0;
  position: relative;
  z-index: 5;
}

.admin-container {
  flex-grow: 1;
  display: flex;
  height: calc(100vh - 76px);
}

.admin-content-wrapper {
  flex-grow: 1;
  overflow-y: auto;
  padding: 25px 40px;
}

//TODO: fix
.admin-content-inner {
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.5);
  font-size: 15px;
  padding: 20px;
  overflow-x: hidden;
}

button {
  cursor: pointer;
}

.admin-btn {
  padding: 11px 18px;
  background: $colorDesign;
  border-color: $colorDesign;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  border-width: 1px;
  cursor: pointer;
  font-weight: 400;

  &:hover {
    background: #598bff;
  }

  &:focus {
    box-shadow: 0 0 0 0.375rem rgba(0, 0, 0, 0.16);
    border-radius: 5px;
  }
}

.modal-footer-buttons {
  &_btn {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

input:not([type='file']),
textarea,
select {
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0 10px;
}

textarea {
  resize: vertical;
  padding: 10px;
}

input[type='color'] {
  padding: 0 2px;
  border-radius: 0;
}

button:disabled {
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.table-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid $backgroundDesign;

  &_add-btn {
    width: 28px;
    cursor: pointer;

    svg {
      fill: #7e9eff;
    }
  }
}

label {
  display: block;
}

.modal-form {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 20px;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }

    div {
      margin-bottom: 8px;
    }
  }

  input,
  textarea,
  select {
    display: block;
    width: 100%;
  }

  textarea {
    min-height: 150px;
  }

  &_btn {
    align-self: flex-end;
  }
}
