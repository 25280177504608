.page-block {
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  .page-button {
    color: #3f729b;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(63, 114, 155, 0.2);
    font-weight: 600;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 5px;
    &:hover {
      color: #fff;
      background-color: #598bff;
    }
  }
  .page-current {
    color: #fff;
    background-color: #36f;
  }
}
