@font-face {
  font-family: 'robotoRegular';
  src: url('roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoBold';
  src: url('roboto-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
