.notifications {
  margin-top: 40px;
  &_header {
    padding: 16px 0;
  }
  &_not {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-description {
      font-weight: 700;
    }
  }
}

.notifications-pass-filter {
  width: 100%;
}

.notifications-list {
  padding-top: 20px !important;

  &_item-inner {
    display: flex;
  }
  &_item-inner {
    border: 1px solid #ece8e8;
    margin-bottom: 20px;
  }

  &_item {
    flex-grow: 1;
    border-right: 1px solid #ece8e8;
    &--id,
    &--status {
      width: 100px;
      flex-shrink: 0;
      flex-grow: 0;
    }
    &--buttons {
      width: 160px;
      flex-grow: 0;
      flex-shrink: 0;
    }
    &-title {
      border-bottom: 1px solid #ece8e8;
      font-weight: bold;
    }
    &-title,
    &-body {
      padding: 10px;
    }

    &-body-inner {
      padding: 10px;
      border-bottom: 1px solid #ece8e8;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
