.left-menu-inner {
  font-family: $rB;
  &_link {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    text-transform: uppercase;
    color: #4a4a4b;
    &--active {
      color: #fff;
      background: blue;
    }
  }
}
