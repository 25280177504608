.table-list {
  background: #fff;
  border-radius: 0.25rem;
  font-size: 15px;
  font-weight: 700;
  margin: 0 -20px;

  &_header {
    padding: 16px 20px;
  }

  &_body-wrapper {
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
  }

  &_row-wrapper {
    flex-wrap: wrap;
  }

  &_item,
  &_row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-shrink: 0;

    &--selected {
      background: #d6e0ff;

      .table-list_item-value {
        border-color: transparent;
      }
    }
  }

  &_item-title,
  &_item-value {
    padding: 10px;
    width: 50%;
    display: flex;
    align-items: center;
    border: 1px solid #f7f9fc;
    border-right: none;
    border-top: none;
    word-break: break-all;

    &--id {
      width: 80px;
      flex-shrink: 0;
    }
  }

  &_item-value {
    font-weight: 400;
  }

  &_item-title {
    text-transform: capitalize;
  }

  &_item-btn {
    width: 38px;
    height: 38px;
    border: none;
    margin-right: 8px;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 5px;

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(1n) {
      background: rgba(63, 114, 155, 0.2);

      svg {
        fill: #3f729b;
      }
    }

    &:nth-child(2n) {
      background: rgba(199, 49, 49, 0.2);

      svg {
        fill: #c73131;
      }
    }

    &:nth-child(3n) {
      background: rgba(0, 214, 143, 0.2);


      svg {
        fill: #00d68f;
      }
    }

    &:nth-child(4n) {
      background: rgba(0, 214, 143, 0.2);


      svg {
        fill: #00d68f;
      }
    }

    &:hover {
      svg {
        fill: #fff;
      }

      &:nth-child(1n) {
        background: #3f729b;
      }

      &:nth-child(2n) {
        background: #c73131;
      }

      &:nth-child(3n) {
        background: #00d68f;
      }
      &:nth-child(4n) {
        background: #00d68f;
      }
    }

    &:disabled {
      background: #f7f7f7;

      svg {
        fill: #cdcdcd;
      }
    }
  }
}
