.pass-editor {
  max-width: $maxWidth;
  margin: 0 auto;

  &_add-btn {
    font-weight: bold !important;
    width: calc((100% / 2) - 20px);
    margin-bottom: 40px;
    // border: 5px dotted $colorDesign;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    padding: 20px;
    background: #f7f8fb;
  }

  &_add-btn-plus {
    width: 50px;
  }

  &_delete-btn {
    // position: absolute;
    // bottom: 100%;
    right: 0;
    // background: $colorDesign;
    // width: 30px;
    height: 30px;
    display: flex;
    justify-content: flex-end;
  }

  &_close-x {
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    padding: 2px;
    margin: 0;
    border: none;
    background: #6c6c6c;
    float: right;

    svg {
      fill: #fff;
    }

    transform: rotate(0deg);
    transition: transform 0.3s;

    &:hover {
      transform: rotate(360deg);
    }
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px !important;
    width: 95%;
    margin: 0 auto;
  }

  &_title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  &_close {
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 50%;
    padding: 2px;
    margin: 0;
    border: none;
    background: $colorDesign;

    svg {
      fill: #fff;
    }

    transform: rotate(0deg);
    transition: transform 0.3s;

    &:hover {
      transform: rotate(360deg);
    }
  }

  &_controllers-items-label {
    margin-bottom: 20px;
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }

  &_controllers-items-label-f {
    margin-bottom: 20px;
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }

  &_controllers-wrapper {
    overflow: hidden;
    padding: 20px;
    margin: -20px;
  }

  &_controllers-items-wrapper {
    margin-bottom: 25px !important;
    width: 95%;
    margin: 0 auto;
  }

  &_controllers-items-wrapper-div {
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid #e5e5e5;
    padding: 20px;
    border-radius: 10px;
    margin-left: -35px;
  }

  &_controllers-item-name {
    font-size: 18px;
    font-weight: bold;
    // font-family: 'Roboto';
    line-height: 21px;
    margin-bottom: 20px;
  }

  &_controllers-items-inner {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    align-items: flex-start;
  }

  &_controllers-items-inner-f {
    display: flex;
    flex-wrap: wrap;
    // margin-right: -20px;
    justify-content: space-between;
    align-items: flex-start;
  }

  &_controllers-item {
    width: calc((100% / 4) - 20px);
    margin-right: 20px;
    margin-bottom: 15px;
    flex-grow: 1;

    input,
    textarea,
    select {
      width: 100%;
    }
  }

  &_controllers-item-label {
    margin-bottom: 8px;
    // font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  &_submit-btn-div {
    width: 90%;
    margin: 0 auto;
  }

  &_submit-btn {
    float: right;
    width: 144px;
    height: 40px;
    // font-family: 'Roboto';
    font-size: 16px;
    background: #396cfc;
  }
}

.pass-editor-icons {
  margin-right: 0;

  &_item-wrapper {
    width: calc((100% / 4) - 20px);
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 15px;
    background: #e8e8e8;
    border-radius: 5px;
    text-align: center;
    position: relative;
    padding-bottom: calc(10% + 30px);
  }

  &_item-preview-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:hover {
      .pass-editor-icons_item-delete {
        right: 10px;
      }
    }
  }

  &_item-label {
    line-height: 30px;
    padding: 0 5px;
    box-shadow: -5px -5px 5px -5px rgba(34, 60, 80, 0.6) inset;
  }

  &_item-preview {
    height: 100%;
    display: block;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-label {
      display: inline-block;
      padding-top: 40px;
      background: url('../../assets/file-load.svg') 50% 0 no-repeat;
      margin-top: 10px;

      span {
        color: $colorDesign;
      }
    }

    &--empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      cursor: pointer;
    }
  }

  &_item-delete {
    position: absolute;
    top: 10px;
    right: -50px;
    border-radius: 50%;
    background: #fff;
    width: 35px;
    height: 35px;
    transition: right 0.2s;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    padding: 5px;
  }
}

input[type='file'] {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.pass-editor {
  textarea {
    height: 120px;
    resize: none;
  }

  &_block {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
  }

  &_block-item-label {
    margin-bottom: 8px;
    color: black;
  }

  &_block-item-wrapper {
    width: calc((100% / 2) - 20px);
    margin-right: 20px;
    margin-bottom: 40px;
    // background: $colorDesign;
    background: #f7f8fb;
    padding: 20px;
    // color: #fff;
    position: relative;
  }

  &_block-item-inner {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
      width: 100%;
    }
  }
}

.primary-field {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;

  .pass-editor_controllers-item {
    width: calc(100% - 20px);
  }

  .pass-editor_controllers-item-l {
    width: calc((100% / 2));

    input,
    textarea,
    select {
      width: 97%;
    }
  }
}
