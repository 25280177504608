.auth-form {
  background-color: $backgroundDesign;
  padding: 20px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &_inner {
    position: relative;
    background: #fff;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.4);
    height: 80%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  &_body-wrapper {
    width: 60%;
  }
  &_title {
    text-align: center;
    font-size: 2.25rem;
    font-family: $rB;
    line-height: 3rem;
    color: #192038;
    margin-bottom: 20px;
  }
  &_controllers-item {
    width: 100%;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 0.4375rem 1.125rem;
    margin-bottom: 32px;
  }

  &_btn {
    width: 100%;
    color: #fff;
    font-family: $rB;
    text-transform: uppercase;
    padding: 0.8125rem 1.125rem;
    font-size: 1rem;
  }
}
