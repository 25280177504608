.admin-header {
  background: #fff;
}

.left-menu-show-toggle-btn {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.user-info {
  position: relative;
}

.user-login {
  background: transparent;
  border: 1px solid #e2e2e2;
  padding: 10px 25px;
  border-radius: 2px;
}

.user-menu {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px !important;
  width: 100%;
  box-shadow: -2px 5px 48px 2px rgba(34, 60, 80, 0.2);
  text-align: center;
  li {
    padding: 5px;
    background: #fff;
    cursor: pointer;
    &:hover {
      background: $backgroundDesign;
    }
  }
}
