$rR: 'robotoRegular';
$rB: 'robotoBold';
$maxWidth: 1500px;
$colorDesign: #396CFC;
$backgroundDesign: #edf1f7;
$textColor: #435464;
$errorTextColor: red;
$textSize: 16px;
$linkColor: #ff3300;
$siteFont: $rR,
sans-serif;
$h1Size: 36px;